import React, { useState, useEffect, useRef, Fragment } from 'react'

export default ({ className, page, calc, data, buttonName = "Оставить заявку" }) => {

    const [ name, setName ] = useState('')
    const [ phone, setPhone ] = useState('')
    const [ isOpen, setIsOpen ] = useState(false)

    const modal = useRef(null)
    const submitButton = useRef(null)
    const annotation = useRef(null)

    useEffect(() => {
        if(modal) {
            if(!isOpen)
                modal.current.classList.remove('feedback__modal--show')
            else
                modal.current.classList.add('feedback__modal--show')
        }
    }, [ isOpen ])

    const onSubmit = (e) => {
        e.preventDefault()

        submitButton.current.disabled = true

        const dataToSend = { form: "modal", name, phone, page }
        if(calc && data) {
            dataToSend.form = "calc"
            dataToSend.region = data.region
            dataToSend.power = data.power
            dataToSend.kbm = data.kbm
            dataToSend.period = data.period
            dataToSend.age = data.age
            dataToSend.exp = data.exp
        }

        fetch("https://emalion.clockwork.one/send?app=ingood", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataToSend)
        }).then((res) => {
            if(res.ok)
                return res.json()
            throw Error(`Error with status: ${ res.statusText }`)
        }).then(({ http_code }) => {
            if(http_code === 200) {
                annotation.current.classList.add("feedback__annotation--success")
            } else {
                submitButton.current.disabled = false
                annotation.current.classList.add("feedback__annotation--error")
                annotation.current.innerText = "При отправке заявки произошла ошибка! Пожалуйста, попробуйте позже."
            }
        }).catch((err) => {
            console.error(err)
            submitButton.current.disabled = false
            annotation.current.classList.add("feedback__annotation--error")
            annotation.current.innerText = "При отправке заявки произошла ошибка! Пожалуйста, попробуйте позже."
        })
    }

    return (
        <Fragment>
            <button className={ className } onClick={ () => setIsOpen(true) }>{buttonName}</button>
            <div className="feedback__modal" ref={ modal }>
                <div className="home__feedback__form--modal">
                    <div className="home__feedback__header">
                        <h2 className="home__feedback__title--modal">Мы Вам перезвоним!</h2>
                        <span className="close" onClick={ () => setIsOpen(false) }>&times;</span>
                    </div>
                    <form onSubmit={ onSubmit }>
                        <input type="text" value={ name } name="name" placeholder="Имя" onChange={ ({ target: { value } }) => setName(value) } />
                        <input type="text" value={ phone } name="phone" placeholder="Телефон" onChange={ ({ target: { value } }) => setPhone(value) } />
                        <button type="submit" ref={ submitButton } className="button button--green button--feedback">Отправить</button>
                        <p ref={ annotation } className="feedback__annotation">Ваша заявка успешно отправлена!</p>
                    </form>
                </div>
            </div>
        </Fragment>
    )

}